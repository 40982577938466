import { API_BASE_URL } from '../constants/urls';
import { getAccessToken, authenticate, tokenExpired, logout, isAuthenticated } from './auth';

const _apiHost = API_BASE_URL; // provisory

export enum RESPONSE_STATUSES {
  'UNAUTHORIZED' = 401,
  'PAYMENT_REQUIRED' = 402,
  'FORBIDDEN' = 403,
  'OK' = 200,
  'CREATED' = 201,
  'NO_CONTENT' = 204,
}

enum CONTENT_TYPES {
  'multipart/form-data' = 'multipart/form-data',
  'application/json' = 'application/json',
}

export async function request(url: string, params?: any, method?: string, contentType?: string) {
  const expired = tokenExpired();
  const tokenIsValid = isAuthenticated();

  if (expired) await authenticate();
  if (!tokenIsValid) logout();

  const headers: any = {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getAccessToken().token}`,
  }

  if (contentType !== CONTENT_TYPES['multipart/form-data']){
    headers['Content-Type'] = CONTENT_TYPES['application/json'];
  }

  const options: any = {
    method,
    headers
  };

  if (params) {
    if (method === 'GET') url += `?${objectToQueryString(params)}`;
    else if (method === 'DELETE') url += params;
    else if (contentType === CONTENT_TYPES['multipart/form-data']) options.body = params;
    else options.body = JSON.stringify(params);
  }

  try {
    const response: any = await fetch(_apiHost + url, options);
    const responseStatusFirstNumber = Number(response.status.toString().charAt(0));
    if (response.status === RESPONSE_STATUSES.NO_CONTENT) return response;

    const result = await response.json();
    if (responseStatusFirstNumber === 2) return result;

    throw {
      message: result.message || 'The server responded with an unexpected status.',
      status: response.status,
    };
  } catch (err) {
    const error: any = err;
    if (error.status === RESPONSE_STATUSES.UNAUTHORIZED || error.status === RESPONSE_STATUSES.FORBIDDEN) {
      logout();
      window.location.href = '/login';
      return;
    }
    return generateErrorResponse(error.message, error.status);
  }
}

function objectToQueryString(obj: any) {
  const queryString = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&').replace(/\+/g, '%2B');

  return queryString;
}

function generateErrorResponse(message: string, statusCode: number) {
  return {
    status: 'error',
    statusCode,
    message,
  };
}
