import { Catalog, Level } from "../Catalog/interfaces";

export enum MODAL_TYPES {
  'changeDriver' = 0,
  'confirmDriver' = 1,
  'changeOrder' = 2,
  'addProduct' = 3,
  'showRouteInfo' = 4,
  'editWindowModal' = 5,
  'confirmReplacement' = 6
}

export enum ORDER_STATUSES {
  'CREATED' = 'CREATED',
  'IN_PICKING' = 'IN_PICKING',
  'PREPARED' = 'PREPARED',
  'COLLECTED' = 'COLLECTED',
  'DELIVERED' = 'DELIVERED',
  'CANCELLED_BY_CUSTOMER' = 'CANCELLED_BY_CUSTOMER',
  'CANCELLED_BY_KIWIME' = 'CANCELLED_BY_KIWIME',
}

export enum DISCOUNT_TYPES {
  'PERCENTAGE' = 'PERCENTAGE',
  'VALUE' = 'VALUE',
}

export enum EXPENSES_VALUES {
  "TAX" = "TAX",
  "DELIVERY_FEE" = "DELIVERY_FEE",
  "DELIVERY_FEE_TAX" = "DELIVERY_FEE_TAX",
  "TIP" = "TIP"
}
export enum LEG_TYPE {
  'DELIVER' = 'DELIVER',
  'COLLECT' = 'COLLECT',
}

export const EDITABLE_WINDOWS = ["COLLECTED", "DELIVERED", "CANCELLED_BY_CUSTOMER", "CANCELLED_BY_KIWIME"];

export type TaxesType = {
  id: string;
  name: string;
  calculatedValue: number;
  value: number;
  type: 'PERCENTAGE' | 'VALUE'
}

export type ExpensesType = {
  name: string;
  unit: string;
  value: number;
  type: EXPENSES_VALUES;
}

export type ProductType = {
  productId: string;
  sku: number;
  price: number;
  marketPrice: number;
  unit: string;
  qty: number;
  name: string;
  additionalInfo: null;
  upc: number;
  unavailable: false;
  taxes: Array<TaxesType>,
  tax: number;
  locationId: string;
  catalogId: string;
  catalogProvider: string;
  mdImageLink: string;
}

export interface InventoryProduct extends ProductType {
  id: string;
  originalQty: number;
  unitOfMeasurement: string;
  active: boolean;
  levels: Level[];
  catalog: Catalog;
}
export interface OrderType {
  id: string;
  total: number;
  subTotal: number;
  discount: number;
  taxes: number;
  deliveryFee: number;
  tip: number;
  coupon: string;
  locationId?: string;
  zoneId: string;
  items: Array<ProductType>;
  destination: {
    address: string;
    lat: number;
    lng: number;
    deliveryInstructions: string;
  };
  customerDetails: {
    displayName: string;
    customerId: string;
    deviceId: string;
    deviceRegistrationToken: string;
    email: string;
    phone: string;
  };
  expenses: Array<ExpensesType>;
  createdAt: number;
  updatedAt: number;
  cancelledAt: number;
  arrivedAtWarehouse: number;
  actualPickingStartTime: number;
  estimatedPickingStartTime: number;
  actualPickingEndTime: number;
  estimatedPickingEndTime: number;
  actualCollectTime: number;
  estimatedCollectTime: number;
  arrivedAtCustomer: number;
  actualDeliveryTime: number;
  estimatedDeliveryTime: number;
  status: string;
  paymentDetails: {
    paymentMethod: string;
    status: string;
    createdAt: number;
    updatedAt: number;
  };
  comments: string;
  assignedDriverId: string;
  deliveryWindowId: string;
  originalTotal: number;
  lastUpdatedBy: string;
}
export interface WindowsType {
  id: string;
  startTime: number;
  endTime: number;
  locationId: string;
  zoneId: string;
  totalCapacity: number;
  filledCapacity: number;
  price: number;
  available: boolean;
  asap: boolean;
}
export interface OrderLegType {
  id: string;
  routeId?: string;
  orderId: string;
  type: string;
  position: number;
  address: string;
  packages: number;
  instructions: string;
  latitude: number;
  longitude: number;
  actualStartTime?: string;
  estimatedEndTime?: string;
  actualEndTime?: string;
  accumulatedTime?: string;
  accumulatedDistance?: string;
  accumulatedCost?: string;
  createdAt: number;
  updatedAt: number;
}
export interface DeliveryWindowsType {
  date: string;
  windows: Array<WindowsType>
}

export interface Tax {
  id: string,
  name: string,
  type: string,
  taxRate: number,
  value: number,
  calculatedValue: number
}
