/* eslint-disable @typescript-eslint/no-unused-expressions */
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';
import { CartProduct, Product } from '../Catalog/interfaces';
import { ExpensesType, OrderType, ProductType, EXPENSES_VALUES, Tax, InventoryProduct, TaxesType } from './interfaces'
export const formatHours = (unixDateTime: number) => {
  // SET FOR CALGARY TIMEZONE FOR NOW!!
  if (unixDateTime === 0) return '-';
  else {
    const dateTimeFormatted = moment(unixDateTime).tz(DEFAULT_TIMEZONE).format("HH:mm")
    return dateTimeFormatted;
  }
};

export const formatDate = (unixTime: number) => {
  const dateFormatted = moment(unixTime).tz(DEFAULT_TIMEZONE).format("DD/MM/YYYY")
  if (unixTime === 0) return '';
  return dateFormatted;
};

export const handleDownloadCsvFiles = (order: OrderType) => {
  const orderId = order?.id;
  const orderIdLast7Digits = orderId?.substr(orderId?.length - 7);
  const orderDate = moment.utc(order?.estimatedDeliveryTime).local().format('YYYY-MM-DD');

  const groupedProductsByProvider = Object.values(order?.items.reduce(function (memo: any, x: ProductType) {
    if (!memo[x['catalogProvider']]) { memo[x['catalogProvider']] = []; }
    memo[x['catalogProvider']].push(x);
    return memo;
  }, {}));

  groupedProductsByProvider.forEach((providerProducts: Array<ProductType>) => {
    const productsRows = providerProducts.map((product: ProductType) => {
      const valuesForCsv = [
        'CO',
        orderIdLast7Digits,
        '',
        '139860',
        'KiwiMe Inc',
        '',
        '',
        '',
        '',
        '',
        product.sku,
        product.name.substring(0, 48),
        product.qty.toString(),
        `'${product.upc}`,
        '',
        orderDate,
        '',
        product.catalogProvider
      ];

      return valuesForCsv;
    });

    const rows = [
      [
        'Order Type',
        'PO Number',
        'Customer Number',
        'Distributor Customer No.',
        'Customer Name',
        'Customer Address',
        'Customer City',
        'Consumer Province',
        'Consumer Postal Code',
        'Product Code',
        'Item Code',
        'Item Description',
        'Qty Ordered',
        'UPC',
        'Lot Number',
        'Order Date',
        'Expected Ship Date',
        'Provider'
      ],
      ...productsRows,
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e: any) => e.join(',')).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    const today = moment().format('YYYY-MM-DD');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `CO_${orderIdLast7Digits}_${today}_${providerProducts[0].catalogProvider}.CSV`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download CSV file containing products of each provider".
  });
};

export const calculateTax = (taxes: any[], price: number, qty: number) => {
  let taxSum = 0;

  taxes.forEach(tax => {
    if (tax.type === 'VALUE') {
      taxSum = taxSum + tax.value;
    } else {
      taxSum = taxSum + (100 * tax.value) / price * qty;
    }
  })

  return taxSum;
}

export const getLocationBasedOnLevels = (productId: string, levels: any[]) => {
  const foundProduct = levels.find(item => item.productId === productId);
  return foundProduct.locationId;
}

export const generateExpensesArray = (products: CartProduct[], deliveryFeeTax?: number) => {

  let expenses: ExpensesType[] = [];

  products.forEach(product => {
    product.taxes.forEach(tax => {
      if (tax.type === 'PERCENTAGE'){
        const taxInCents = (product.price * tax.value) / 100;
	      const taxRoundedUp = Math.ceil(taxInCents);
        tax.calculatedValue = taxRoundedUp;
      }

      else if (tax.type === 'VALUE') tax.calculatedValue = tax.value;

      const taxName = tax.name;
      const foundTax = expenses.findIndex(expense => expense.name === taxName);
      if (foundTax !== -1) {
        const previousObject = expenses[foundTax];

        if (previousObject){
          expenses[foundTax] = {
            ...previousObject,
            value: previousObject.value + (tax.calculatedValue * product.qty)
          }
        }

      } else {
        expenses.push({
          name: tax.name,
          type: EXPENSES_VALUES.TAX,
          unit: '$',
          value: tax.calculatedValue * product.qty
        });
      }

    })
  })

  if (deliveryFeeTax) {
    expenses = expenses.map(expense => {
    if (expense.name === 'GST') {
      return ({
      ...expense,
      value: expense.value + deliveryFeeTax
    })} else return expense;
  })}

  return expenses;
}

export const mergeGSTTaxes = (expenses: ExpensesType[]) => {

  const foundGSTDeliveryTax = expenses.find(item => item.type === EXPENSES_VALUES.DELIVERY_FEE_TAX);

  const fixedExpenses = expenses.map(expense => {
    if (foundGSTDeliveryTax && expense.name === 'GST') {
      return ({
      ...expense,
      value: expense.value + foundGSTDeliveryTax.value
    })} else return expense;
  })

  return fixedExpenses;
}

export const transformBigDecimal = (value: any) => {
  const with2Decimals = Number(value.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  return with2Decimals;
};

export const calculateTotalTaxes = (taxes: TaxesType[], price: number) => {
  const totalTax = taxes?.reduce((acc: any, tax: any) => {
    if (tax.type === 'PERCENTAGE'){
      const taxInCents = (price * tax.value) / 100;
      const taxRoundedUp = Math.ceil(taxInCents);
      return acc + taxRoundedUp;
    } else {
      return acc + tax.value
    }
  }, 0);

  return totalTax;
}

export const filterProductsByTaxesAndPrice = (allProducts: InventoryProduct[], replacedProduct: InventoryProduct) => {

  const replacedProductTaxesIds = replacedProduct.taxes.map(item => item.id);

  const productsFiltered = allProducts.filter(product => {
    const currentProductTaxesIds = product.taxes.map(item => item.id);

    if (product.taxes.length === 0) return product;

    else if(replacedProductTaxesIds.sort().join(',') === currentProductTaxesIds.sort().join(',')){
      return product
    }

  })

  return productsFiltered;
}

export const removeReplacedProduct = (allProducts: InventoryProduct[], productId: string) => {

  return allProducts.filter(product => product.id !== productId);
}

export const checkReplacementAvailability = (qty: number, replacement: InventoryProduct) => {

  if (replacement.levels[0].availableStock >= qty) return true;

  return false;
}
