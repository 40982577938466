/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col, Spin, Button, Popconfirm, notification, Rate, Typography, Tooltip } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';
import formatToDollar from 'util/formatToDollar';
import { capitalizeFirstLetter } from 'util/capitalizeFirstLetter';
import { ReactComponent as EditIcon } from '../../../../../img/editIcon.svg';
import {
  AddProductModal,
  EditProductModal,
  RouteInfoModal,
  EditWindowsModal,
  Invoice,
  EstimatedAndRealTimeSteps,
} from '..';
import styles from './OrderDetailsPage.module.css';
import Table from '../Table';
import { EDITABLE_WINDOWS, ExpensesType, EXPENSES_VALUES, MODAL_TYPES, ORDER_STATUSES } from '../../interfaces';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { handleDownloadCsvFiles } from '../../utils';
import EditLegsAddressModal from '../EditLegsAddressModal';
import ConfirmReplacementModal from '../ConfirmReplacementModal';


const OrderDetailsPage = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const [isChangeLegAddressModalOpen, setIsChangeLegAddressModalOpen] = useState<boolean>(false);

  const {
    toggleModalVisibility,
    fetchOrder,
    order,
    loading,
    updateOrderData,
    changeOrderStatus,
    hasChanges,
    firstOrderTotal,
    customer,
    orderExpenses,
  } = useContext(OrderDetailsContext);

  useEffect(() => {
    fetchOrder(id);
  }, [id]);

  const handleSaveAllChanges = () => {
    const { total, originalTotal, subTotal, discount, taxes, deliveryFee, coupon, items, comments, assignedDriverId } =
      order;
    const availableProducts = items.filter((product: any) => !product.unavailable);
    const hasAvailableProducts = availableProducts.length > 0;

    if (hasAvailableProducts) {
      if (total <= originalTotal) {
        const payload = {
          id: order.id,
          body: {
            total: total <= 0 ? 0 : total,
            subTotal,
            discount,
            taxes,
            deliveryFee,
            coupon: discount > 0 ? coupon : '',
            items,
            comments,
            assignedDriverId,
            replacements: order.replacements || null
          },
        };
        updateOrderData(payload);
      } else {
        notification.error({
          message: 'Error editing product!',
          description: `The new order total can not be bigger than the current total | total: ${Number(firstOrderTotal) / 100
            } - new total: ${Number(total) / 100}`,
        });
      }
    } else changeOrderStatus({ id: order.id, body: { status: 'CANCELLED_BY_KIWIME' } });
  };

  const handleOpenChangeLegAddressModal = () => {
    setIsChangeLegAddressModalOpen(true)
  }

  const handleCloseChangeLegAddressModal = (value: any) => {
    setIsChangeLegAddressModalOpen(value)
  }

  const handleRouteClick = () => {
    toggleModalVisibility(MODAL_TYPES.showRouteInfo);
  };

  const text = 'Are you sure you want to go back without saving your changes?';

  const hasAvailableProducts = useMemo(() => order?.items?.filter((product: any) => !product.unavailable), [order]);

  const setInvoiceName = (orderId: string) => {
    const orderIdSevenLastDigits = orderId.substr(orderId.length - 7);
    return `Invoice-${orderIdSevenLastDigits}`;
  };

  const defineIfWindowIsEditable = (orderStatus: string) => {
    const isWindowEditable = EDITABLE_WINDOWS.includes(orderStatus);
    return isWindowEditable;
  };

  const concatRatingComments = (ratings: any) => {
    const comments = ratings.map((rating: any) => rating.comments);
    return [].concat(...comments);
  };

  const numberOfPackages = (number: number) => {
    if (order.status === ORDER_STATUSES.COLLECTED ||
      order.status === ORDER_STATUSES.DELIVERED) {
      return number
    }
    else if (order.status === ORDER_STATUSES.CREATED ||
      order.status === ORDER_STATUSES.IN_PICKING ||
      order.status === ORDER_STATUSES.PREPARED) {
      return "-"
    }
  }

  return (
    <>
      {loading ? (
        <div className={styles.spinContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {order && (
            <div className={styles.container}>
              {hasChanges ? (
                <Popconfirm placement="topRight" title={text} onConfirm={() => history.goBack()}>
                  <div className={styles.goBackContainer}>
                    <LeftOutlined className={styles.goBackIcon} />
                    <h1 className={styles.goBackTitle}>Go back</h1>
                  </div>
                </Popconfirm>
              ) : (
                <div className={styles.goBackContainer} onClick={() => history.goBack()}>
                  <LeftOutlined className={styles.goBackIcon} />
                  <h1 className={styles.goBackTitle}>Go back</h1>
                </div>
              )}
              <Row className={styles.infosContainer}>
                <Col lg={5} md={24} xs={24} className={styles.orderInfoContainer}>
                  <h1 className={styles.title}>Order Info</h1>
                  <p className={styles.text}>Order ID: {order.id || '-'}</p>
                  {order?.orderNumber && <p className={styles.text}>Order Number: {order.orderNumber}</p>}
                  <p className={styles.text}>Delivered: {order.actualDeliveryTimeFormatted || '-'}</p>
                  <p className={styles.text}>City: {order.city || 'Calgary'}</p>
                  <p className={styles.text}>Warehouse: {order.warehouse || '-'}</p>
                  <div className={styles.routeIdContainer}>
                    <p className={styles.text}>
                      <InfoCircle className={styles.goToDetailsButton} onClick={handleRouteClick} /> Route ID:{' '}
                      {order.routeLegs[0]?.routeId || '-'}
                    </p>
                  </div>
                  <p className={styles.text}>Coupon: {order.coupon || '-'}</p>
                  <p className={styles.text}>Last Updated By: {order.lastUpdatedBy || '-'}</p>
                  <p className={styles.text}>Nº of Packages: {numberOfPackages(order.numberOfPackages ?? '-')}</p>
                  <Button onClick={() => handleDownloadCsvFiles(order)} className={styles.csvButton}>
                    Provider CSVs
                  </Button>
                </Col>

                <Col lg={14} md={24} xs={24} className={styles.statusContainer}>
                  <div className={styles.statusContent}>
                    <h1 className={styles.title}>Status: {order.status || '-'}</h1>
                    <EstimatedAndRealTimeSteps />
                  </div>
                  <Button
                    disabled={defineIfWindowIsEditable(order.status)}
                    onClick={() => toggleModalVisibility(MODAL_TYPES.editWindowModal)}
                    className={styles.editWindowButton}
                  >
                    Edit Windows
                  </Button>
                </Col>
                <Col lg={5} md={24} xs={24} className={styles.customerInfoContainer}>
                  <h1 className={styles.title}>Customer Info</h1>
                  <strong style={{ cursor: 'pointer' }}
                    onClick={() => history.push('/customers')}
                    className={styles.textAlignRight}>
                    <InfoCircle className={styles.goToDetailsButton} />
                    ID: {order.customerDetails.customerId || '-'}
                  </strong>
                  <p className={styles.textAlignRight}>Name: {order.customerDetails.displayName || '-'}</p>
                  <p className={styles.textAlignRight}>E-mail: {order.customerDetails.email || '-'}</p>
                  <p className={styles.textAlignRight}>Phone: {order.customerDetails.phone || '-'}</p>
                  <Row align={'middle'}>
                    <Tooltip title="Edit Legs Addresses">
                      <EditIcon onClick={handleOpenChangeLegAddressModal} className={styles.editIcon} style={{ marginRight: 10, cursor: 'pointer' }} />
                    </Tooltip>,
                    <Typography.Text strong style={{ maxWidth: '180px', textAlign: 'right' }}>Address: {order.destination.address || '-'}</Typography.Text>
                  </Row>
                  <p className={styles.textAlignRight}>{order.comments || ''}</p>
                  <p className={styles.textAlignRight}>
                    Delivery Instructions: {order.destination.deliveryInstructions || '-'}
                  </p>
                  <PDFDownloadLink
                    document={<Invoice order={order} customer={customer} />}
                    fileName={setInvoiceName(order.id)}
                  >
                    <Button className={styles.csvButton}>Invoice</Button>
                  </PDFDownloadLink>
                </Col>
              </Row>

              <Table />
              <Row className={styles.buttonsRow}>
                <div />
                <Button
                  disabled={!hasChanges}
                  className={`${hasChanges ? styles.button : styles.disabled}`}
                  onClick={() => handleSaveAllChanges()}
                >
                  Save All Changes
                </Button>
              </Row>
              <div className={styles.rowContainer}>
                <div className={styles.paymentContainer}>
                  <h1 className={styles.title}>Payment information</h1>
                  <p className={styles.text}>Method: {order.paymentDetails.paymentMethod}</p>
                  <p className={styles.text}>Status: {order.paymentDetails.status}</p>
                </div>

                {order.ratings.length > 0 && (
                  <div className={styles.ratingContainer}>
                    <h1 className={styles.title}>Rating By User:</h1>
                    {order.ratings.map((rating: any) => {
                      return (
                        <div key={rating.id} className={styles.ratings}>
                          <p className={styles.text}>{capitalizeFirstLetter(rating.type)} Rating:</p>
                          <Rate className={styles.ratingStars} value={rating.stars} />
                        </div>
                      );
                    })}
                    <p className={styles.text}>Comments:</p>
                    <div className={styles.commentsContainer}>
                      {concatRatingComments(order.ratings).map((comment: any) => {
                        return <p className={styles.text}>{comment}</p>;
                      })}
                    </div>
                  </div>
                )}

                <div className={styles.valuesContainer}>
                  <div>
                    <p className={styles.text}>Subtotal:</p>
                    <p className={styles.text}>Delivery Fee:</p>
                    <p className={styles.text}>Driver Tip:</p>
                    <p className={styles.text}>Coupon:</p>
                    <strong className={styles.text}>Taxes</strong>
                    {order.expenses?.map((expense: ExpensesType) =>
                      expense.type === EXPENSES_VALUES.TAX ? (
                        <p key={expense.name} className={styles.text}>
                          {expense.name}
                        </p>
                      ) : null,
                    )}
                    <p className={styles.text}>Service Fee:</p>
                    <p className={styles.text}>GST:</p>
                    <p className={styles.text}>Kiwi Credits:</p>
                  </div>
                  <div>
                    <p className={styles.textAlignRight}>
                      ${hasAvailableProducts.length > 0 ? formatToDollar(order.subTotal) : `$${formatToDollar(0)}`}
                    </p>
                    <p className={styles.textAlignRight}>${formatToDollar(order.deliveryFee)}</p>
                    <p className={styles.textAlignRight}>${formatToDollar(order.tip)}</p>
                    <p className={styles.textAlignRight}>${formatToDollar(order.discount)}</p>
                    <br />
                    {orderExpenses?.map((expense: ExpensesType) =>
                      expense.type === EXPENSES_VALUES.TAX ? (
                        <p key={expense.name} className={styles.textAlignRight}>{`${expense.unit}${formatToDollar(
                          expense.value,
                        )}`}</p>
                      ) : null,
                    )}
                    <p className={styles.textAlignRight}>${formatToDollar(order.serviceFee)}</p>
                    <p className={styles.textAlignRight}>${formatToDollar(order.serviceFeeTax)}</p>
                    <p className={styles.kiwiCreditsText}>- ${formatToDollar(order.walletBalanceUsed)}</p>
                  </div>
                </div>
              </div>

              <div className={styles.horizontalDivider} />

              <div className={styles.totalPaymentContainer}>
                <p className={styles.totalText}>Total: </p>
                <p className={styles.textAlignRight}>
                  ${hasAvailableProducts.length > 0 ? formatToDollar(order.total) : 0}
                </p>
              </div>
            </div>
          )}
        </>
      )}
      <EditWindowsModal />
      <EditProductModal />
      <AddProductModal />
      <RouteInfoModal />
      <ConfirmReplacementModal />
      <EditLegsAddressModal handleChangeModalVisibility={handleCloseChangeLegAddressModal} isModalOpen={isChangeLegAddressModalOpen} orderId={order?.id} />
    </>
  );
};

export default OrderDetailsPage;
