/* eslint-disable prefer-const */
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Table, Input, Button, Select, DatePicker, Radio } from 'antd';

import useOrders from 'hooks/useOrders';
import styles from './OrdersPage.module.css';
import MultipleStatusSelect from './components/MultipleStatusSelect';
import { FilterTypes, ORDER_STATUSES } from './interfaces';
import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';
import OrderStatusSelect from 'ui/components/OrderStatusSelect';
import EditOrderStatusModal from 'ui/components/EditOrderStatusModal';
import { OrdersPageGlobalFiltersContext } from '../../../contexts/OrdersPageGlobalFiltersContext';
import { buildOrderFiltersQueryString } from './utils';
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone, formatDate } from 'util/dateAndTime';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Orders = (): any => {
  const {
    loading,
    paginatedOrders,
    fetchOrders,
    changeModalVisibility,
    storeNewStatus,
    isModalVisible,
    newStatus,
    updateOrder
  } = useOrders();

  const { statuses, setStatuses, intervalDates, setIntervalDates } = useContext(OrdersPageGlobalFiltersContext);
  const history = useHistory();

  const [orderToBeEditedId, setOrderToBeEditedId] = useState<any>(null);
  const [radioValue, setRadioValue] = useState<string>('desc');
  const [searchText, setSearchText] = useState<string>('');
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.ORDER_ID);
  const deliveryWindowsFilters: any = history.location.state;

  const handleSearch = async (
    page: number | null = null,
    windowId: string | null = null,
    windowOrderStatus: ORDER_STATUSES | null = null
    ) => {
    const params: any = {};

    params.orderBy = 'estimated_delivery_time';
    params.orderByMode = radioValue;
    let currentStatuses = statuses;

    if (page) params.page = page;
    if (searchText !== '') params[filterType] = searchText;
    if (windowOrderStatus) currentStatuses = [windowOrderStatus];
    if (windowId) params.deliveryWindowId = windowId;

    if (intervalDates) {
      const deliveryDateStartTime = getUnixTimeByTimezone(intervalDates[0].format(), 5, 0, DEFAULT_TIMEZONE);
      const deliveryDateEndTime = getUnixTimeByTimezone(intervalDates[1].format(), 23, 40, DEFAULT_TIMEZONE);

      if (deliveryDateStartTime) params.deliveryDateStartTime = deliveryDateStartTime;
      if (deliveryDateEndTime) params.deliveryDateEndTime = deliveryDateEndTime;
    }

    const queryString = buildOrderFiltersQueryString(params, currentStatuses);

    fetchOrders(queryString);
  };

  useEffect(() => {
    if (!deliveryWindowsFilters) handleSearch();
    else {

      let windowOrderStatus = null;
      let deliveryWindowId = null;

      if (deliveryWindowsFilters.orderStatus) {
        setStatuses([deliveryWindowsFilters.orderStatus]);
        windowOrderStatus = deliveryWindowsFilters.orderStatus;
      }
      if (deliveryWindowsFilters.windowId) {
        setSearchText(deliveryWindowsFilters.windowId);
        setFilterType(FilterTypes.WINDOW_ID);
        deliveryWindowId = deliveryWindowsFilters.windowId;
      }

      handleSearch(null, deliveryWindowId, windowOrderStatus);

      window.history.replaceState({}, document.title);
    }
  }, [deliveryWindowsFilters, radioValue]);

  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'orderID',
      key: 'orderID',
      render: (id: string, record: any) => (
        <div className={styles.orderIdContainer}>
          <InfoCircle className={styles.goToDetailsButton} onClick={() => history.push(`/orders/${record.id}`)} />
          <p className={styles.text}>{id}</p>
        </div>
      ),
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (value: string) => <p>{value ?? '-'}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (currentStatus: string, record: any) => (
        <OrderStatusSelect
          style={{ width: 220 }}
          value={currentStatus}
          allowClear={false}
          onChange={(value: ORDER_STATUSES) => handleOrderStatusChange(value, record)}
        />
      ),
    },
    {
      title: 'Delivery Window',
      dataIndex: 'deliveryWindow',
      key: 'deliveryWindow',
      render: (value: number) => <p>{formatDate(value)}</p>,
    },
    {
      title: 'Customer name',
      dataIndex: 'customerName',
    },
    {
      title: 'Customer email',
      dataIndex: 'customerEmail',
    },
    {
      title: 'Customer phone',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
  ];

  const handleOrderStatusChange = (newStatus: any, order: any) => {
    changeModalVisibility(true)
    storeNewStatus(newStatus);
    setOrderToBeEditedId(order.id);
  }

  const selectAfter = (
    <Select
      className="select-after"
      style={{ width: '200px', backgroundColor: '#d9d9d6' }}
      value={filterType}
      onChange={(value) => setFilterType(value)}
    >
      <Option value={FilterTypes.ORDER_ID}>Order ID</Option>
      <Option value={FilterTypes.WINDOW_ID}>Delivery Window ID</Option>
      <Option value={FilterTypes.CUSTOMER_NAME}>Customer Name</Option>
      <Option value={FilterTypes.CUSTOMER_EMAIL}>Customer Email</Option>
      <Option value={FilterTypes.CUSTOMER_PHONE}>Customer Phone</Option>
    </Select>
  );

  return (
    <div className={styles.cardContainer}>
      <Row className={styles.mainRow}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Col lg={24} md={24} xs={24}>
            <div className={styles.compoundFilter}>
              <Input
                className={styles.inputSearch}
                addonBefore={selectAfter}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                allowClear
              />
              <MultipleStatusSelect />
              <RangePicker
                format="DD-MM-YYYY"
                value={intervalDates}
                allowEmpty={[true, true]}
                allowClear
                onChange={(e: any) => setIntervalDates(e)}
                className={styles.rangePicker}
              />
              <Button disabled={loading} onClick={() => handleSearch()} type="primary" icon={<SearchOutlined />}>
                Search
              </Button>
            </div>
          </Col>
          <div className={styles.productSortContainer}>
            <h1 className={styles.productSortTitle}>Products Sorting (Estimated Delivery Time):</h1>
            <Radio.Group
              value={radioValue}
              defaultValue="both"
              onChange={(e) => {
                setRadioValue(e.target.value);
              }}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
          <Table
            loading={loading}
            rowKey={(record) => record.id}
            className={styles.table}
            dataSource={paginatedOrders?.results}
            columns={columns}
            scroll={{ x: 1270 }}
            pagination={{
              onChange: (page: number) => {
                handleSearch(page);
              },
              total: paginatedOrders?.totalItems,
              pageSize: 10,
              showSizeChanger: false,
              current: paginatedOrders?.currentPage,
            }}
          />
        </Col>
      </Row>
      <EditOrderStatusModal
        orderId={orderToBeEditedId}
        changeModalVisibility={changeModalVisibility}
        isModalVisible={isModalVisible}
        newStatus={newStatus}
        updateOrder={updateOrder}
      />
    </div>
  );
};

export default Orders;
